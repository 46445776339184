<template>
  <div>
    <div class="home-container">
      <section>
        <h1
          class="text-center display-3 font-weight-bold accent--text pt-16 mt-md-10"
        >
          Impulsa tu gestión médica con <br />
          <span class="light_primary--text">XUMED</span>
        </h1>
        <p class="title accent--text mt-10">
          Optimiza tus procesos y mejora la atención al paciente con <br />
          nuestro software
        </p>
        <v-btn to="/registro" color="primary" depressed class="mt-5">
          <span style="text-transform: capitalize"
            >Pruébalo
            <span style="text-transform: lowercase">gratis 30 días</span>
          </span>
        </v-btn>
      </section>
      <section class="text-left section">
        <h1 class="display-1 font-weight-bold accent--text">
          Diseñado para agilizar tu día
        </h1>
        <div class="row no-gutters">
          <div class="col-md col-12 pr-md-2 mt-5">
            <div
              class="feature-card"
              :class="
                $vuetify.theme.dark ? 'feature-card-dark' : 'feature-card-light'
              "
            >
              <div class="text-center">
                <v-avatar slot="icon" color="primary" size="50" dark>
                  <v-icon color="white" size="25">mdi-calendar</v-icon>
                </v-avatar>
                <h3 class="title light_primary--text mt-5">Agendamiento</h3>
                <div class="mt-2">
                  Nuesto módulo de agendas permite optimizar tus horarios y
                  maximizar tu atención.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md col-12 px-md-2 mt-5">
            <div
              class="feature-card"
              :class="
                $vuetify.theme.dark ? 'feature-card-dark' : 'feature-card-light'
              "
            >
              <div class="text-center">
                <v-avatar slot="icon" color="primary" size="50" dark>
                  <v-icon color="white" size="25">mdi-clipboard-text</v-icon>
                </v-avatar>
                <h3 class="title light_primary--text mt-5">Historia clínica</h3>
                <div class="mt-2">
                  Gestiona el historial clínico de tus pacientes de una manera
                  100% personalizable.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md col-12 px-md-2 mt-5">
            <div
              class="feature-card"
              :class="
                $vuetify.theme.dark ? 'feature-card-dark' : 'feature-card-light'
              "
            >
              <div class="text-center">
                <v-avatar slot="icon" color="primary" size="50" dark>
                  <v-icon color="white" size="25">mdi-currency-usd</v-icon>
                </v-avatar>
                <h3 class="title light_primary--text mt-5">Finanzas</h3>
                <div class="mt-2">
                  Puedes llevar un control preciso de tus ingresos, asegurando
                  una salud financiera sólida.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md col-12 pr-md-2 mt-5">
            <div
              class="feature-card"
              :class="
                $vuetify.theme.dark ? 'feature-card-dark' : 'feature-card-light'
              "
            >
              <div class="text-center">
                <v-avatar slot="icon" color="primary" size="50" dark>
                  <v-icon color="white" size="25">mdi-cog</v-icon>
                </v-avatar>
                <h3 class="title light_primary--text mt-5">Inventario</h3>
                <div class="mt-2">
                  Administra los servicios que ofreces a tus pacientes y mantén
                  tus suministros médicos al día.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-2">
        <h1 class="display-1 text-center font-weight-bold accent--text">
          Simplifica tu trabajo y enfócate en lo que realmente importa:
          <span class="gradient-text">la salud de tus pacientes</span>
        </h1>
      </section>
      <section class="row text-left section-2">
        <div class="col-12 col-md-6 px-md-5">
          <h3 class="title accent--text mt-5">
            <v-icon color="light_primary" class="mb-1"
              >mdi-cloud-upload-outline</v-icon
            >
            Técnologia en la nube
          </h3>
          <v-divider></v-divider>
          <div class="mt-2">
            Puedes gestionar tus operaciones de manera eficiente, con acceso
            seguro y sin interrupciones desde cualquier lugar y en cualquier
            momento.
          </div>
        </div>

        <div class="col-12 col-md-6 px-md-5">
          <h3 class="title accent--text mt-5">
            <v-icon color="light_primary" class="mb-1"
              >mdi-shield-lock-outline</v-icon
            >
            Altos estandares de seguridad
          </h3>
          <v-divider class="mt-1"></v-divider>
          <div class="mt-2">
            Implementamos las mejores prácticas y tecnologías avanzadas para
            asegurarnos de que tu información esté siempre a salvo y protegida
            contra cualquier amenaza.
          </div>
        </div>

        <div class="col-12 col-md-6 px-md-5">
          <h3 class="title accent--text mt-5">
            <v-icon color="light_primary" class="mb-1">mdi-face-agent</v-icon>
            Soporte gratis las 24 horas
          </h3>
          <v-divider></v-divider>
          <div class="mt-2">
            Nuestro equipo de expertos está disponible las 24 horas del día, los
            7 días de la semana, para responder a tus consultas y resolver
            cualquier problema que puedas encontrar.
          </div>
        </div>

        <div class="col-12 col-md-6 px-md-5">
          <h3 class="title accent--text mt-5">
            <v-icon color="light_primary" class="mb-1"
              >mdi-creation-outline</v-icon
            >
            Interfaz amigable
          </h3>
          <v-divider></v-divider>
          <div class="mt-2">
            Queremos que te concentres en tu trabajo, no en aprender a usar una
            herramienta compleja. Con una navegación clara y una disposición
            lógica, podrás dominar nuestro software en poco tiempo.
          </div>
        </div>
      </section>
      <section class="section">
        <h1 id="planes" class="display-1 font-weight-bold accent--text">
          Nuestros planes
        </h1>
        <v-btn-toggle
          class="mt-5"
          v-model="plan"
          color="light_primary"
          group
          mandatory
        >
          <v-btn value="month" class="plan-btn" style="border-radius: 8px">
            Mensual
          </v-btn>
          <v-btn value="year" class="plan-btn" style="border-radius: 8px">
            <div>Anual</div>
          </v-btn>
        </v-btn-toggle>
        <div class="relative">
          <div aria-hidden="true" class="background-blur">
            <div class="blur bg-gradient-1 d-none d-md-block"></div>
            <div class="blur bg-gradient-2 d-none d-md-block"></div>
          </div>
          <section>
            <div class="row mt-5">
              <div class="col-md-4 col-12">
                <div class="plan-card pa-5">
                  <v-chip color="success">
                    <h1 class="title font-weight-bold">Standard</h1>
                  </v-chip>
                  <div class="mt-5">
                    <div class="display-1 accent--text font-weight-bold">
                      {{ plan == "month" ? "$10" : "$99" }}
                      <span class="title accent--text">usd</span>
                    </div>
                    <div
                      v-if="plan == 'year'"
                      class="font-weight-bold caption mt-1"
                    >
                      ¡Obtienes 2 meses gratis!
                    </div>
                  </div>
                  <v-divider class="my-5"></v-divider>
                  <div class="mt-5 text-left px-3">
                    <div class="row items-center mt-2">
                      <v-icon color="success" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">1 usuario con acceso</h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="success" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        2 formatos de plantillas para tu historial
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="success" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        50 productos/servicios en tu inventario
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="success" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Control de pagos</h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="success" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Reportes de gestión</h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="success" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Soporte gratis 24/7</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="plan-card pa-5">
                  <v-chip color="primary">
                    <h1 class="title font-weight-bold">Pro</h1>
                  </v-chip>
                  <div class="mt-5">
                    <div class="display-1 accent--text font-weight-bold">
                      {{ plan == "month" ? "$18" : "$179" }}
                      <span class="title accent--text">usd</span>
                    </div>
                    <div
                      v-if="plan == 'year'"
                      class="font-weight-bold caption mt-1"
                    >
                      ¡Obtienes 2 meses gratis!
                    </div>
                  </div>
                  <v-divider class="my-5"></v-divider>
                  <div class="mt-5 text-left px-3">
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        2 usuarios con acceso, uno para ti y otro para tu
                        secretario/a o asistente
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        5 formatos de plantillas para tu historial
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        300 productos/servicios en tu inventario
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Gestión de documentos de tus pacientes
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Control de pagos</h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Control de gastos</h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Reportes de gestión</h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Envío de facturas por email
                      </h4>
                    </div>
                    <div class="row items-center mt-2">
                      <v-icon color="light_primary" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Soporte gratis 24/7</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="plan-card pa-5">
                  <v-chip color="black" dark>
                    <h1 class="title font-weight-bold premium-text">Premium</h1>
                  </v-chip>
                  <div class="mt-5">
                    <div class="display-1 accent--text font-weight-bold">
                      <div class="display-1 accent--text font-weight-bold">
                        {{ plan == "month" ? "$25" : "$249" }}
                        <span class="title accent--text">usd</span>
                      </div>
                    </div>
                    <div
                      v-if="plan == 'year'"
                      class="font-weight-bold caption mt-1"
                    >
                      ¡Obtienes 2 meses gratis!
                    </div>
                  </div>
                  <v-divider class="my-5"></v-divider>
                  <div class="mt-5 text-left">
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">5 usuarios con acceso</h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        15 formatos de plantillas para tu historial
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Productos/servicios en tu inventario de manera ilimitada
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Gestión de documentos de tus pacientes
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Control de pagos</h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Control de gastos</h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Reportes de gestión</h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Envío de facturas por email
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Multicentros</h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Confirmaciones de citas (próximamente)
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Visibilidad online de tu perfil (próximamente)
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">
                        Agendamiento online (próximamente)
                      </h4>
                    </div>
                    <div class="row items-center mt-2 px-3">
                      <v-icon color="black" class="mb-1 col-1"
                        >mdi-check</v-icon
                      >
                      <h4 class="accent--text col">Soporte gratis 24/7</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section id="contacto" class="section">
        <h1 class="display-1 font-weight-bold accent--text">
          Contáctanos a cualquier hora
        </h1>
        <div class="title accent--text mt-1">
          <v-icon color="success" class="mb-1">mdi-whatsapp</v-icon>
          Puedes escribir o llamar al <br class="d-md-none d-flex-inline" />
          <span
            @click="openWhatsapp"
            style="text-decoration: underline; cursor: pointer"
            >+1 (809) 248-5229</span
          >
        </div>
      </section>
      <footer class="accent--text section">
        <div
          class="d-md-flex align-end justify-md-space-between my-3 mx-1 subtitle-2 font-weight-regular"
        >
          <div class="text-left">
            <div>Desarrollado por Grupo Videlsa SRL</div>
            <div>
              <v-icon small class="mb-1">mdi-map-marker</v-icon>
              La Vega, República Dominicana.
            </div>
          </div>
          <div>
            <div class="row d-md-flex d-none">
              <v-btn icon @click="openInstagram" class="mr-1 mb-2">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn icon @click="openWhatsapp" class="mb-2">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div
          class="d-md-flex align-center justify-md-space-between my-md-5 mt-5 mx-1 subtitle-2 font-weight-regular"
        >
          <div class="text-left">
            © {{ currentYear }} XUMED. Todos los derechos reservados.
          </div>
          <div>
            <div class="d-md-flex d-none">
              <router-link
                class="mr-5 accent--text"
                style="text-decoration: none"
                :to="{ path: '/legal', hash: '#politicas' }"
                >Políticas de privadidad</router-link
              >

              <router-link
                class="accent--text"
                style="text-decoration: none"
                :to="{ path: '/legal', hash: '#terminos' }"
                >Términos y condiciones</router-link
              >
            </div>
          </div>
        </div>
        <div class="d-block d-md-none text-left subtitle-2 font-weight-regular">
          <div class="mt-2">
            <router-link
              class="accent--text"
              style="text-decoration: none"
              :to="{ path: '/legal', hash: '#politicas' }"
              >Políticas de privacidad</router-link
            >
          </div>
          <div class="mt-2">
            <router-link
              class="accent--text"
              style="text-decoration: none"
              :to="{ path: '/legal', hash: '#terminos' }"
              >Términos y condiciones</router-link
            >
          </div>
          <div class="d-flex my-2">
            <v-btn color="accent" class="mx-1" icon @click="openInstagram">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn color="accent" class="mx-1" icon @click="openWhatsapp">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Home",
  data() {
    return {
      currentYear: moment().format("YYYY"),
      plan: "month",
    };
  },
  methods: {
    openWhatsapp() {
      window.open(
        `https://api.whatsapp.com/send/?phone=${18092485229}`,
        "_blank"
      );
    },
    openInstagram() {
      window.open(`https://www.instagram.com/xumed.rd`, "_blank");
    },
  },
};
</script>

<style scoped>
.home-container {
  padding-top: 100px;
  padding: 0px 100px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0px 20px;
  }
}

.section {
  margin-top: 150px;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
}

.section-2 {
  margin-top: 120px;
  @media (max-width: 768px) {
    margin-top: 75px;
  }
}

.plan-card {
  background: rgba(253, 253, 253, 0.237);
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-color: 1 solid white;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
}

.plan-btn {
  width: 200px;
  @media (max-width: 768px) {
    width: 120px;
  }
}

.feature-card {
  width: 100%;
  height: 100%;
  padding: 25px 0px;
  border: solid;
  border-radius: 8px;
  border-width: thin;
  @media (max-width: 768px) {
    padding: 25px;
  }
}

.feature-card-light {
  border-color: rgba(0, 0, 0, 0.12);
}

.feature-card-dark {
  border-color: rgba(255, 255, 255, 0.205);
}

.gradient-text {
  background: linear-gradient(to right, #1d7def, #2eb6b2);
  -webkit-background-clip: text;
  color: transparent;
}

.premium-text {
  background: linear-gradient(to right, #76b2fc, #66fdf8);
  -webkit-background-clip: text;
  color: transparent;
}

.text-center {
  text-align: center;
  max-width: 66.6667%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
</style>
